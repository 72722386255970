import { createElement } from 'react';
import { SvgIcon, type SvgIconProps } from '@mui/material';
function Clear(
  props: SvgIconProps & {
    'data-testid'?: string;
  }
) {
  return createElement(
    SvgIcon,
    Object.assign(
      {},
      { viewBox: '0 0 24 24', fill: 'none', 'data-testid': 'ClearIcon' },
      props
    ),
    <g fill="currentColor">
      <path d="M8.795 8.794a1.005 1.005 0 0 1 1.421 0L12 10.578l1.784-1.783a1.005 1.005 0 1 1 1.422 1.421L13.422 12l1.784 1.784a1.005 1.005 0 0 1-1.422 1.421L12 13.422l-1.784 1.784a1.005 1.005 0 0 1-1.421-1.422L10.579 12l-1.784-1.784a1.005 1.005 0 0 1 0-1.422" />
      <path d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12s4.477 10 10 10 10-4.477 10-10m-2 0a8 8 0 1 1-16 0 8 8 0 0 1 16 0" />
    </g>
  );
}
export default Clear;
