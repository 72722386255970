import { createElement } from 'react';
import { SvgIcon, type SvgIconProps } from '@mui/material';
function ChevronLeft(
  props: SvgIconProps & {
    'data-testid'?: string;
  }
) {
  return createElement(
    SvgIcon,
    Object.assign(
      {},
      { viewBox: '0 0 24 24', fill: 'none', 'data-testid': 'ChevronLeftIcon' },
      props
    ),
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M15.197 4.292a1 1 0 0 1 .002 1.414L8.921 12l6.278 6.294a1 1 0 0 1-1.416 1.412l-6.982-7a1 1 0 0 1 0-1.412l6.982-7a1 1 0 0 1 1.415-.002Z"
      clipRule="evenodd"
    />
  );
}
export default ChevronLeft;
