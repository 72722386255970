import { createElement } from 'react';
import { SvgIcon, type SvgIconProps } from '@mui/material';
function Filter(
  props: SvgIconProps & {
    'data-testid'?: string;
  }
) {
  return createElement(
    SvgIcon,
    Object.assign(
      {},
      { viewBox: '0 0 24 24', fill: 'none', 'data-testid': 'FilterIcon' },
      props
    ),
    <path
      fill="currentColor"
      d="M10.855 4.5a3.502 3.502 0 0 0-6.71 0H2a1 1 0 0 0 0 2h2.145a3.502 3.502 0 0 0 6.71 0H22a1 1 0 1 0 0-2zM9 5.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m1.855 12a3.502 3.502 0 0 0-6.71 0H2a1 1 0 1 0 0 2h2.145a3.502 3.502 0 0 0 6.71 0H22a1 1 0 1 0 0-2zM9 18.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m7.5-3a3.5 3.5 0 0 1-3.355-2.5H2a1 1 0 1 1 0-2h11.145a3.502 3.502 0 0 1 6.71 0H22a1 1 0 1 1 0 2h-2.145a3.5 3.5 0 0 1-3.355 2.5m0-5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3"
    />
  );
}
export default Filter;
