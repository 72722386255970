import { createElement } from 'react';
import { SvgIcon, type SvgIconProps } from '@mui/material';
function Plus(
  props: SvgIconProps & {
    'data-testid'?: string;
  }
) {
  return createElement(
    SvgIcon,
    Object.assign(
      {},
      { viewBox: '0 0 24 24', fill: 'none', 'data-testid': 'PlusIcon' },
      props
    ),
    <path
      fill="currentColor"
      d="M13 6a1 1 0 1 0-2 0v5H6a1 1 0 1 0 0 2h5v5a1 1 0 1 0 2 0v-5h5a1 1 0 1 0 0-2h-5z"
    />
  );
}
export default Plus;
