import { createElement } from 'react';
import { SvgIcon, type SvgIconProps } from '@mui/material';
function LockedOn(
  props: SvgIconProps & {
    'data-testid'?: string;
  }
) {
  return createElement(
    SvgIcon,
    Object.assign(
      {},
      { viewBox: '0 0 24 24', fill: 'none', 'data-testid': 'LockedOnIcon' },
      props
    ),
    <path
      fill="currentColor"
      d="M12 1a5 5 0 0 0-5 5v3a3 3 0 0 0-3 3v8a3 3 0 0 0 3 3h10.59A2.41 2.41 0 0 0 20 20.59V12a3 3 0 0 0-3-3V6a5 5 0 0 0-5-5m3 8H9V6a3 3 0 1 1 6 0zm-3 9.173a2.173 2.173 0 1 1 0-4.346 2.173 2.173 0 0 1 0 4.346"
    />
  );
}
export default LockedOn;
