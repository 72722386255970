import { createElement } from 'react';
import { SvgIcon, type SvgIconProps } from '@mui/material';
function Logout(
  props: SvgIconProps & {
    'data-testid'?: string;
  }
) {
  return createElement(
    SvgIcon,
    Object.assign(
      {},
      { viewBox: '0 0 24 24', fill: 'none', 'data-testid': 'LogoutIcon' },
      props
    ),
    <g fill="currentColor">
      <path d="M13 3a1 1 0 0 1-1 1H6a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h6a1 1 0 1 1 0 2H6a4 4 0 0 1-4-4V6a4 4 0 0 1 4-4h6a1 1 0 0 1 1 1" />
      <path d="M18.172 7.757a1 1 0 1 0-1.414 1.415L18.586 11H13a1 1 0 1 0 0 2h5.586l-1.828 1.829a1 1 0 1 0 1.414 1.414l3.535-3.536a1 1 0 0 0 0-1.414z" />
    </g>
  );
}
export default Logout;
