import { createElement } from 'react';
import { SvgIcon, type SvgIconProps } from '@mui/material';
function Search(
  props: SvgIconProps & {
    'data-testid'?: string;
  }
) {
  return createElement(
    SvgIcon,
    Object.assign(
      {},
      { viewBox: '0 0 24 24', fill: 'none', 'data-testid': 'SearchIcon' },
      props
    ),
    <path
      fill="currentColor"
      d="m13.192 14.607 7.1 7.1a1 1 0 1 0 1.415-1.414l-7.1-7.1a7 7 0 1 0-1.414 1.414ZM14 9A5 5 0 1 1 4 9a5 5 0 0 1 10 0"
    />
  );
}
export default Search;
