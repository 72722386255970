import { createElement } from 'react';
import { SvgIcon, type SvgIconProps } from '@mui/material';
function Help(
  props: SvgIconProps & {
    'data-testid'?: string;
  }
) {
  return createElement(
    SvgIcon,
    Object.assign(
      {},
      { viewBox: '0 0 24 24', fill: 'none', 'data-testid': 'HelpIcon' },
      props
    ),
    <g fill="currentColor">
      <path d="M13 17a1 1 0 1 1-2 0 1 1 0 0 1 2 0m-2.5-7.5a1.5 1.5 0 1 1 2.576 1.045l-.258.26c-.31.31-.694.693-1 1.085-.411.53-.818 1.236-.818 2.11a1 1 0 1 0 2 0c0-.231.105-.507.397-.882.221-.284.473-.536.761-.824q.168-.166.353-.355A3.5 3.5 0 1 0 8.5 9.5a1 1 0 1 0 1.999 0Z" />
      <path d="M12 23c6.075 0 11-4.925 11-11S18.075 1 12 1 1 5.925 1 12s4.925 11 11 11m0-2a9 9 0 1 1 0-18 9 9 0 0 1 0 18" />
    </g>
  );
}
export default Help;
