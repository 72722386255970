import { createElement } from 'react';
import { SvgIcon, type SvgIconProps } from '@mui/material';
function PieChart(
  props: SvgIconProps & {
    'data-testid'?: string;
  }
) {
  return createElement(
    SvgIcon,
    Object.assign(
      {},
      { viewBox: '0 0 24 24', fill: 'none', 'data-testid': 'PieChartIcon' },
      props
    ),
    <g fill="currentColor">
      <path d="M4.517 17A9 9 0 0 1 3 12a1 1 0 1 0-2 0A11 11 0 1 0 12 1a1 1 0 1 0 0 2 9 9 0 1 1-7.483 14" />
      <path d="M9.879 9.879a1.5 1.5 0 1 0-2.121-2.122 1.5 1.5 0 0 0 2.12 2.122Zm6.364 4.242a1.5 1.5 0 1 1-2.122 2.122 1.5 1.5 0 0 1 2.122-2.122m-8.486.708a1 1 0 0 0 1.414 1.414l7.072-7.071a1 1 0 0 0-1.415-1.415z" />
    </g>
  );
}
export default PieChart;
